defineDs('/Components/DanskeSpil/Domain/Feature.Components/Scripts/ResponsibleGaming', [
  'DanskeSpil/Framework/TimeUtils/Scripts/TimeUtils',
], function (TimeUtils) {

  const topElement = document.querySelector('.js-responsible-gaming-top');
  const menuElement = document.querySelector('.js-responsible-gaming-menu');

  if (!topElement || !menuElement) {
    return;
  }

  const clockElement = topElement.querySelector('.js-responsible-gaming-top-clock');
  const openMenuToggle = topElement.querySelector('.js-responsible-gaming-top-toggle');
  const closeMenuToggle = menuElement.querySelector('.js-responsible-gaming-menu-toggle');

  const openMenu = () => {
    document.dispatchEvent(new CustomEvent('overlay:navigation:show'));
    menuElement.classList.add('open');
  };

  const closeMenu = () => {
    document.dispatchEvent(new CustomEvent('overlay:navigation:hide'));
    menuElement.classList.remove('open');
  };

  const updateClock = () => {
    const now = TimeUtils.getCurrentDateTime();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    clockElement.textContent = `${hours}:${minutes}`;
  };

  if (clockElement) {
    updateClock();
    setInterval(updateClock, 1000);
  }

  const initialize = () => {
    openMenuToggle?.addEventListener('click', openMenu);
    closeMenuToggle?.addEventListener('click', closeMenu);

    document.addEventListener('responsible-gaming:close', () => {
      closeMenu();
    });
  };

  initialize();

});
