defineDs('/Components/Common/Framework/Foundation.SharedScripts/Scripts/Libraries/WheelSlider',
  [
    '/Components/Common/Framework/Foundation.SharedScripts/Scripts/Utils/SliderUtils'
  ], function (SliderUtils) {
    const defaultSettings = {
      containerSelector: '.slider-container',
      controls: {
        enabled: true,
        next: '.next',
        previous: '.previous',
        disabledClasses: []
      },
      elementClasses: {
        active: [],
        passive: [],
        general: []
      },
      settings: {
        enableMouseDrag: false,
      }
    };

    return class WheelSlider {
      _container = null;
      _containerWrapper = null;
      _slides = null;
      _containerRect = null;
      _centerX = 0;
      _centerY = 0;
      _offsetValue = 0;
      _currentIndex = 0;
      _velocityMultiplier = 0.99;
      _minVelocityToStart = 25;
      _maxAllowedVelocity = 50;
      _stopVelocity = 1;
      _diffAngle = 19;
      _totalAngleShift = 0;
      _lastAngleShift = 0;
      _lastXs = [];
      _lastTimes = [];
      _mouseDownPosition = 0;
      _mouseUpPosition = 0;
      _lastClickTimestamp = null;
      _isDragging = false;
      _isMouseDown = false;
      _isStopped = false;
      _targetAtRelease = null;
      _velocity = 0;
      _velocityMax = 0;
      _slidesOutOfView = [];
      _onTotalAngleShiftChange = () => {};

      constructor(userSettings) {

        this._containerSelector = userSettings.containerSelector || defaultSettings.containerSelector;
        this._settings = { ...defaultSettings.settings, ...userSettings.settings };
        this._controls = { ...defaultSettings.controls, ...userSettings.controls };
        this._elementClasses = { ...defaultSettings.elementClasses, ...userSettings.elementClasses };

        this._init();
      }

      set onTotalAngleShiftChange(func) {
        if (typeof func !== 'function') throw new Error('Total angle shift change must be a function');
        this._onTotalAngleShiftChange = func;
      }

      set onNextClick(func) {
        if (typeof func !== 'function') throw new Error('Next click must be a function');
        this._onNextClick = func;
      }

      set onPreviousClick(func) {
        if (typeof func !== 'function') throw new Error('Previous click must be a function');
        this._onPreviousClick = func;
      }

      set onDragEnd(func) {
        if (typeof func !== 'function') throw new Error('Drag end must be a function');
        this._onDragEnd = func;
      }

      _callDragEnd() {
        this._totalAngleShift = this._lastAngleShift;
        if (this._onDragEnd) this._onDragEnd();
      }

      _callNextClick() {
        if (this._onNextClick) this._onNextClick();
      }

      updateTotalAngleShift(angleChange) {
        this._totalAngleShift += angleChange;
      }

      _callPreviousClick() {
        if (this._onPreviousClick) this._onPreviousClick();
      }

      _callTotalAngleShiftChange(angle) {
        this._onTotalAngleShiftChange(angle);
        this._lastAngleShift = angle;
      }

      _init() {

        if (typeof this._containerSelector === 'string') {
          this._container = document.querySelector(this._containerSelector);
        } else if (this._containerSelector instanceof HTMLElement) {
          this._container = this._containerSelector;
        }

        if (this._controls.enabled && this._controls.next && this._controls.previous) {
        // Next button
          if (typeof this._controls.next === 'string') {
            this._nextButton = document.querySelector(this._controls.next);
          } else if (this._controls.next instanceof HTMLElement) {
            this._nextButton = this._controls.next;
          }

          // previous button
          if (typeof this._controls.previous === 'string') {
            this._previousButton = document.querySelector(this._controls.previous);
          } else if (this._controls.previous instanceof HTMLElement) {
            this._previousButton = this._controls.previous;
          }
        } else {
          this._controls.enabled = false;
        }

        if (!this._container) {
          throw new Error('No container found');
        }

        this._slides = [...this._container.querySelectorAll('& > *')];
        if (!this._slides.length) {
          throw new Error('No elements found');
        }

        this._slides.forEach((slide, i) => {
          slide.dataset.index = i;
          if (slide.dataset.active) {
            this._currentIndex = -i;
          }
        });

        this._addEventListeners();

        this._containerWrapper = this._container.parentElement;
        this._containerRect = this._container.getBoundingClientRect();
        this._centerX = this._containerRect.width / 2;

        // TODO: Fix this mess
        this._moveOutOfView();
        this._moveOutOfView();
        this._moveOutOfView();
      }

      _addEventListeners() {
        this._container.addEventListener('touchstart', (event) => this._handleMouseDown(event), { passive: false });
        this._container.addEventListener('touchend', (event) => this._handleMouseUp(event), { passive: false });
        this._container.addEventListener('touchmove', (event) => this._handleMouseMove(event), { passive: false });

        if (this._settings.enableMouseDrag) {
          this._container.addEventListener('mousedown', (event) => this._handleMouseDown(event));
          document.addEventListener('mouseup', (event) => this._handleMouseUp(event));
          document.addEventListener('mousemove', (event) => this._handleMouseMove(event));
        }

        if (this._controls.enabled) {
          this._nextButton.addEventListener('click', () => this._handleControlClick('next'));
          this._previousButton.addEventListener('click', () => this._handleControlClick('previous'));
        }

        this._slides.forEach((slide) => {
          slide.addEventListener('click', () => this._handleClick(slide));
        });

        window.addEventListener('resize', () => {
          this._moveOutOfView();
          this._moveSlides();
        });
        // eslint-disable-next-line compat/compat
        const resizeObserver = new ResizeObserver(() => {
          this._moveOutOfView();
          this._moveSlides();
        });
        resizeObserver.observe(this._container.parentElement);
      }

      _handleClick(slide) {
        const index = Number(slide.dataset.index);
        if (this._isDragging || index === this._findClosest().index) return;

        const direction = index - this._findClosest().index;
        if (direction) this._handleControlClick(direction > 0 ? 'next' : 'previous');
      }

      _handleMouseDown(e) {
        if (e.type === 'touchstart') this._lastXs.push(e.touches[0].clientX);
        this._isMouseDown = true;
        this._isStopped = false;
        this._velocity = 0;
        this._velocityMax = Number.MAX_SAFE_INTEGER;
        this._mouseDownPosition = this._lastXs.at(-1);
      }

      _handleMouseUp() {
        if (!this._isMouseDown) return;

        this._mouseUpPosition = this._lastXs.at(-1);

        this._isMouseDown = false;
      }

      _handleMouseMove(e) {
        if (!this._isDragging && this._isMouseDown) {
          this._isDragging = true;
          this._disableTransitions();
          requestAnimationFrame(() => this._slideMove());
        }

        this._lastXs.push(e.clientX || e.touches[0].clientX);
        this._lastTimes.push(performance.now());
      }

      _handleControlClick(direction) {
        if (this._lastClickTimestamp && Date.now() - this._lastClickTimestamp < 300) return;

        this._lastClickTimestamp = Date.now();
        this._currentIndex = this._currentIndex + (direction === 'next' ? -1 : 1);
        this._moveOutOfView();
        this._moveSlides();
        if (direction === 'next') this._callNextClick();
        else this._callPreviousClick();
      }

      _findClosest() {
        let closestSlide;
        let shortestDistance = Number.MAX_SAFE_INTEGER;
        let shortestAbsoluteDistance = Number.MAX_SAFE_INTEGER;

        for (let slide of this._slides) {
          slide.dataset.active = 'false';
          const rect = slide.getBoundingClientRect();
          const distToCenter =  (rect.left + (rect.width / 2)) - (this._centerX + this._containerRect.left);
          const absoluteDistToCenter = Math.abs(distToCenter);

          if (absoluteDistToCenter < shortestAbsoluteDistance) {
            shortestAbsoluteDistance = absoluteDistToCenter;
            shortestDistance = distToCenter;
            closestSlide = slide;
          }
        }

        return { index: closestSlide.dataset.index, distance: shortestDistance };
      }

      _moveSlides() {
        this._slides.forEach((slide) => {
          const i = Number(slide.dataset.index);
          const hero = document.querySelector(
            `.js-wheel-hero[data-card-id="${slide.dataset.cardId}"]`
          );

          let offset = 0;

          if (-i > this._currentIndex) offset = -this._offsetValue;
          else if (-i < this._currentIndex) offset = this._offsetValue;
          const angle = this._diffAngle * (i + this._currentIndex);

          this._calcAndMove(angle, offset, slide, hero);
        });

      }

      _calcAndMove(angle, offset, slide, hero) {
        const coords = SliderUtils.getCoordsFromAngle(this._container, angle - 90 + offset);
        const percentage = 100 - SliderUtils.scaleValue(Math.abs(angle % 365), [0, this._diffAngle], [0, 100]);
        const heroXPosition = (coords.x + this._container.getBoundingClientRect().left);

        slide.dataset.angle = angle.toString();
        slide.style.rotate = `${angle}deg`;
        slide.style.setProperty('--percentage', percentage);
        slide.style.left = `${coords.x}px`;
        slide.style.top = `${coords.y}px`;
        if (hero) {
          hero.style.setProperty('--percentage', percentage);
          hero.style.setProperty('--x', heroXPosition + 'px');
          hero.style.setProperty('--distToCenter', (heroXPosition - (this._containerWrapper.getBoundingClientRect().width / 2)));
        }
      }

      _moveOutOfView(source = 'button') {
        this._slidesOutOfView = [];
        const containerWrapperRect = this._containerWrapper.getBoundingClientRect();
        for (const slide of this._slides) {
          const divRect = slide.getBoundingClientRect();
          if (divRect.top > containerWrapperRect.bottom) {
            this._slidesOutOfView.push(slide);
          }
        }

        const active = document.querySelector(`[data-index='${this._findClosest().index}']`);

        let highIndex = Number.MIN_SAFE_INTEGER;
        let lowIndex = Number.MAX_SAFE_INTEGER;

        const outOfViewCount = this._slidesOutOfView.reduce((accumulator, currentValue) => {
          const currentIndex = Number(currentValue.dataset.index);
          if (currentIndex > Number(active.dataset.index)) {
            accumulator.right++;
          } else {
            accumulator.left++;
          }

          return accumulator;
        }, { left: 0, right: 0 });

        for (const slide of this._slides) {
          const currentIndex = Number(slide.dataset.index);
          if (currentIndex > highIndex) highIndex = currentIndex;
          if (currentIndex < lowIndex) lowIndex = currentIndex;
        }

        const transitionDuration = source === 'button' ? '1ms' : '0';
        let slideToMove;
        if (outOfViewCount.left < 2) {
          slideToMove = [...this._slides].find((slide) => {
            return Number(slide.dataset.index) === highIndex;
          });
          slideToMove.style.transitionDuration = transitionDuration;
          slideToMove.dataset.index = (lowIndex - 1).toString();
        } else if (outOfViewCount.right < 2) {
          slideToMove = [...this._slides].find((div) => Number(div.dataset.index) === lowIndex);
          slideToMove.style.transitionDuration = transitionDuration;
          slideToMove.dataset.index = (highIndex + 1).toString();
        }

        if (slideToMove) {
          const resetOnTransitionEnd = (event) => {
            event.target.style.transitionDuration = '';
            slideToMove.removeEventListener('transitionend', resetOnTransitionEnd);
          };
          slideToMove.addEventListener('transitionend', resetOnTransitionEnd);
        }
      }

      _disableTransitions() {
        this._slides.forEach((slide) => {
          slide.style.transitionDuration = '0s';
          const hero = document.querySelector(`.js-wheel-hero[data-card-id="${slide.dataset.cardId}"]`);
          if (hero) hero.style.transitionDuration = '0s';
        });

        document.querySelectorAll('.js-dots-wrapper > div').forEach((dot) => {
          dot.style.transitionDuration = '0s';
        });
      }

      _enableTransitions() {
        this._slides.forEach((slide) => {
          slide.style.transitionDuration = '';
          const hero = document.querySelector(`.js-wheel-hero[data-card-id="${slide.dataset.cardId}"]`);
          if (hero) hero.style.transitionDuration = '';
        });

        document.querySelectorAll('.js-dots-wrapper > div').forEach((dot) => {
          dot.style.transitionDuration = '';
        });
      }

      _slideMove() {
        const moved = this._mouseDownPosition - this._lastXs.at(-1);
        let target = -moved / 10;

        for (const slide of this._slides) {
          const i = Number(slide.dataset.index);
          let offset = 0;
          const hero = document.querySelector(
            `.js-wheel-hero[data-card-id="${slide.dataset.cardId}"]`
          );

          if (-i > this._currentIndex) offset = -this._offsetValue;
          else if (-i < this._currentIndex) offset = this._offsetValue;

          if (!this._isMouseDown && !this._isStopped) {

            this._isStopped = true;
            this._targetAtRelease = Math.floor(-moved / 10);
            const distance = Math.sqrt(Math.pow(this._lastXs.at(-1) - this._lastXs.at(-2), 2));
            this._directionAtRelease = this._lastXs.at(-1) > this._lastXs.at(-2) ? -1 : 1;
            const timeDiff = (this._lastTimes.at(-1) - this._lastTimes.at(-2)) / 10;

            this._velocity = Math.max(Math.min(this._maxAllowedVelocity, (distance / timeDiff) * this._directionAtRelease), -this._maxAllowedVelocity);
            this._velocityMax = this._velocity;

          } else if (!this._isMouseDown && this._isStopped) {
            this._velocity *= this._velocityMultiplier;
            target = this._velocity - this._velocityMax + this._targetAtRelease;
          }

          if (this._isStopped && (Math.abs(this._velocity) < this._stopVelocity || Math.abs(this._velocityMax) < this._minVelocityToStart)) {
            this._totalAngleShift += this._lastAngleShift;
            this._enableTransitions();
            this._isDragging = false;
            const closestDiv = this._findClosest().index;

            this._currentIndex = -Number(closestDiv);
            this._moveSlides();
            this._callDragEnd();
            return;
          }

          const angle = (this._diffAngle * (i + this._currentIndex)) + target + offset;

          this._calcAndMove(angle, offset, slide, hero);
        }
        this._moveOutOfView('drag');
        this._callTotalAngleShiftChange(this._totalAngleShift + target);


        requestAnimationFrame(() => this._slideMove());
      }
    };
  });