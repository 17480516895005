defineDs('/Components/DanskeSpil/Domain/Feature.Components/Scripts/Parallax', [], function () {
  const navigation = document.querySelector('.js-navigation');
  const parallaxTop = document.querySelector('.js-parallax-top');
  const parallaxQuote = document.querySelector('.js-parallax-quote');
  const parallaxBottomSticky = document.querySelector('.js-parallax-bottom-sticky');
  const parallaxScrollOver = document.querySelector('.js-parallax-scroll-over');

  if (!navigation || !parallaxTop || !parallaxQuote || !parallaxBottomSticky || !parallaxScrollOver) return;

  const initialize = () => {
    const navigationRect = navigation.getBoundingClientRect();
    const navigationHeight = navigationRect.height;

    const parallaxTopRect = parallaxTop.getBoundingClientRect();
    const parallaxTopHeight = parallaxTopRect.height;

    const parallaxBottomStickyRect = parallaxBottomSticky.getBoundingClientRect();
    const parallaxBottomStickyHeight = parallaxBottomStickyRect.height;

    const windowHeight = window.innerHeight;
    const scroll = window.scrollY;

    const bottom = Math.max(parallaxBottomStickyHeight - windowHeight, 0);
    parallaxBottomSticky.style.bottom = `${bottom * -1}px`;

    let totalHeight = parallaxTopHeight + navigationHeight;
    let opacity = 1 - (scroll / totalHeight);
    opacity = Math.max(0, Math.min(1, opacity)); // Ensure opacity stays between 0 and 1
    parallaxTop.style.opacity = opacity;

    if (opacity === 0) {
      parallaxBottomSticky.style.setProperty('--parallaxBottomStickyTop', '0px');
      parallaxTop.style.visibility = 'hidden';
    } else {
      parallaxTop.style.visibility = 'visible';
      parallaxBottomSticky.style.setProperty(
        '--parallaxBottomStickyTop',
        `${
          parallaxTopHeight - (parallaxBottomSticky.offsetTop - parallaxTop.offsetTop)
        }px`
      );
    }
  };

  window.addEventListener('scroll', initialize);
  window.addEventListener('resize', initialize);

  initialize();
});