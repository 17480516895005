defineDs('/Components/DanskeSpil/Domain/Feature.News/Scripts/Serie.js',
  [
    '/Components/Common/Framework/Foundation.SharedScripts/Scripts/Libraries/CustomSlider'
  ], function (CustomSlider) {
    const series = document.querySelectorAll('.js-serie');

    if (series) {
      series.forEach((serieElement) => {
        const id = serieElement.querySelector('.serie-slider-selector').getAttribute('id');

        const slider = new CustomSlider({
          containerSelector: '#' + id,
          controls: {
            enabled: true,
            next: `.${id}-slider-next`,
            previous: `.${id}-slider-previous`,
            disabledClasses: ['!opacity-0', '!pointer-events-none']
          },
          elementClasses: {
            general: ['w-full', 'shrink-0', 'transition-opacity', 'duration-500', 'ease-in-out', 'opacity-100'],
            passive: ['pointer-events-none', '@672:animate-seriesSlideFadeOut'],
            active: ['@672:animate-seriesSlideFadeIn'],
          },
          settings: {
            center: true,
            enableMouseDrag: false,
            slideBy: 1,
            maxGroupSize: 1,
          }
        });

        // Update slider indicators
        const indicatorsDesktop = document.querySelectorAll(`#${id}-slider-indicators-desktop > div`);
        const indicatorsMobile = document.querySelectorAll(`#${id}-slider-indicators-mobile > div`);
        const updateIndicator = (indicator = 0, activeIndicator = 0) => {
          const color = slider._slides[indicator]?.getAttribute('data-color') || '';

          indicatorsDesktop.forEach((indicator, i) => {
            indicator.style.backgroundColor = color;
            indicator.setAttribute('data-active', `${i === activeIndicator}`);
          });
          indicatorsMobile.forEach((indicator, i) => {
            indicator.style.backgroundColor = color;
            indicator.setAttribute('data-active', `${i === activeIndicator}`);
          });
        };
        updateIndicator();

        // Update slider images
        const updateImage = (indices = 0) => {
          const images = document.querySelectorAll(`#${id}-slider-images > img`);
          images.forEach((image, i) => {
            if (i < indices) image.setAttribute('data-image', 'prev');
            else if (i > indices) image.setAttribute('data-image', 'next');
            else image.setAttribute('data-image', 'current');
          });
        };
        updateImage();

        // Update slider background
        const updateBackground = (indices = 0) => {
          const content = document.getElementById(`${id}-slider-content`);
          const contentInners = document.querySelectorAll(`#${id}-slider-content #${id} > div`);
          contentInners.forEach((inner, i) => {
            const bg = inner.getAttribute('data-color') || '';
            // add bg color to inner
            inner.style.backgroundColor = bg;

            if (i === indices) {
              // if screen with is over 738px
              if (window.innerWidth >= 640) {
                content.style.backgroundColor = bg;
              } else {
                content.style.backgroundColor = 'transparent';
              }
            }
          });
        };
        updateBackground();

        // update indicator, image and background on change slide
        slider.onSlideCallback = (indices) => {
          let activeIndicator;
          if (indices.includes(0)) activeIndicator = 0;
          else if (indices.includes(slider._slides.length - 1)) activeIndicator = 2;
          else activeIndicator = 1;

          updateIndicator(indices[0], activeIndicator);
          updateImage(indices[0]);
          updateBackground(indices[0]);
        };

        // update indicator, image and background on resize
        window.addEventListener('resize', () => {
          updateBackground(slider._index);
          handleSliderControls(serieElement);
        });

        handleSliderControls(serieElement);
      });

    }
    function isDeviceTouchable() {
      return (('ontouchstart' in window) ||
        // eslint-disable-next-line compat/compat
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
    }

    function handleSliderControls(element) {
      element.closest('.group').classList.toggle('js-force-show-controls', !isDeviceTouchable());
    }
  });
