defineDs('/Components/Common/Framework/Foundation.SharedScripts/Scripts/Libraries/CustomSlider', [], function () {
  const defaultSettings = {
    containerSelector: '.slider-container',
    controls: {
      enabled: true,
      next: '.next',
      previous: '.previous',
      disabledClasses: []
    },
    elementClasses: {
      active: [],
      passive: [],
      general: []
    },
    settings: {
      center: true,
      enableMouseDrag: false, // BUGGED!!!
      slideBy: null, // Slides by as many as possible
      maxGroupSize: Number.MAX_SAFE_INTEGER
    }
  };

  /**
   * Creates an instance of the SliderBase class.
   * @class
   * @classdesc
   * SliderBase is a JavaScript class for creating sliders with various features
   * such as touch events, mouse drag, and navigation controls.
   *
   * @param {DefaultSettings} userSettings - User-defined settings to customize the slider.
   */
  return class CustomSlider {

    _currentPosition = 0;
    _touchMovePercentage = 0;
    _movementStartPosition = 0;
    _lastMovementPosition = 0;
    _movement = [];
    _isDragging = false;
    _groupSize = 0;
    _nextButton = null;
    _previousButton = null;
    _container = null;
    _alignElementWithSlides = null;
    _index = 0;
    _slides = [];
    _sizes = {};
    _settings = {};
    _controls = {};
    _elementClasses = {};
    _lastEvent = null;
    _touchAngleCoordinates = [];
    _touchAngleThresholdSurpassed = false;
    _touchAngleMargin = 30;
    _containerSelector = '';
    _totalGroupWidth = 0;
    _onSlideCallback = () => {
    };
    _percentageChangeCallback = () => {
    };


    constructor(userSettings) {
      this._containerSelector = userSettings.containerSelector || defaultSettings.containerSelector;
      this._settings = { ...defaultSettings.settings, ...userSettings.settings };
      this._controls = { ...defaultSettings.controls, ...userSettings.controls };
      this._elementClasses = { ...defaultSettings.elementClasses, ...userSettings.elementClasses };
      this._alignElementWithFirstSlide = userSettings.alignElementWithFirstSlide || defaultSettings.alignElementWithFirstSlide;
      this._init();

    }

    _init() {
      if (typeof this._containerSelector === 'string') {
        this._container = document.querySelector(this._containerSelector);
      } else if (this._containerSelector instanceof HTMLElement) {
        this._container = this._containerSelector;
      }
      if (typeof this._alignElementWithFirstSlide === 'string') {
        this._alignElementWithSlides = document.querySelector(this._alignElementWithFirstSlide);
      } else if (this._alignElementWithFirstSlide instanceof HTMLElement) {
        this._alignElementWithSlides = this._alignElementWithFirstSlide;
      }
      if (this._controls.enabled && this._controls.next && this._controls.previous) {
        // Next button
        if (typeof this._controls.next === 'string') {
          this._nextButton = document.querySelector(this._controls.next);
        } else if (this._controls.next instanceof HTMLElement) {
          this._nextButton = this._controls.next;
        }

        // previous button
        if (typeof this._controls.previous === 'string') {
          this._previousButton = document.querySelector(this._controls.previous);
        } else if (this._controls.previous instanceof HTMLElement) {
          this._previousButton = this._controls.previous;
        }
      }

      if (!this._container) {
        console.error('No container found');
        return;
      }

      this._slides = [...this._container.querySelectorAll('& > *')];
      if (!this._slides.length) {
        console.error('No elements found');
        return;
      }

      this._addEventListeners();
      this._updateAttributes();
      this._setControlClasses();
      if (this._settings.center) {
        this._centerSlides();
      } else {
        this._currentPosition = 0;
      }

      this._slides.forEach((element, i) => {
        element.id = '_' + i;
        element.classList.add(...this._elementClasses.general);
      });
    }

    _centerSlides() {
      const distanceToCenter = ((this._sizes.container.width / 2) + this._sizes.container.left) - ((this._sizes.elements[this._index].left + this._sizes.elements[this._index + this._groupSize - 1].right) / 2);
      this._currentPosition = distanceToCenter;
      this._container.style.transform = `translateX(${distanceToCenter}px)`;
      if (this._alignElementWithSlides) {
        this._alignElementWithSlides.style.transform = `translateX(${distanceToCenter}px)`;
      }
    }

    _updateAttributes() {
      this._updateSizes();
      this._calculateSizes();
      this._calculateGroupSize();
      if (this._settings.center) {
        this._centerSlides();
      }
      this._setActiveClasses();
    }

    _calculateGroupSize() {
      for (let i = this._slides.length; i > 0; i--) {
        this._totalGroupWidth = Math.floor((this._sizes.elements[0].width * i) + (this._sizes.spaceBetweenSlides * (i - 1)));
        if (this._totalGroupWidth <= this._sizes.container.width) {
          this._groupSize = Math.min(i, this._settings.maxGroupSize);
          break;
        }
      }
    }

    _updateSizes() {
      this._sizes.container = this._container.getBoundingClientRect();
      this._sizes.container.center = (this._sizes.container.width / 2) + this._sizes.container.left;
      this._sizes.elements = this._slides.map((element) => element.getBoundingClientRect());
    }

    _addEventListeners() {
      this._container.addEventListener('touchstart', (event) => this._handleTouchStart(event), { passive: false });
      this._container.addEventListener('touchend', (event) => this._handleTouchEnd(event), { passive: false });
      this._container.addEventListener('touchmove', (event) => this._handleTouchMove(event), { passive: false });

      if (this._settings.enableMouseDrag) {
        this._container.addEventListener('mousedown', (event) => this._handleMouseDown(event));
        window.addEventListener('mouseup', (event) => this._handleMouseUp(event));
        window.addEventListener('mousemove', (event) => this._handleMouseMove(event));
      }
      if (this._controls.enabled) {
        this._nextButton.addEventListener('click', () => this._handleControlClick('next'));
        this._previousButton.addEventListener('click', () => this._handleControlClick('previous'));
      }
      window.addEventListener('resize', () => this._updateAttributes());
      // eslint-disable-next-line compat/compat
      const resizeObserver = new ResizeObserver(() => this._updateAttributes());
      resizeObserver.observe(this._container.parentElement);
    }

    // -- Mouse events --
    _handleMouseDown(event) {
      this._movement.push(event.clientX);
      this._container.style.transitionDuration = 'unset';
      this._movementStartPosition = event.clientX;
      this._isDragging = true;

    }

    _handleMouseMove(event) {
      this._movement.push(event.clientX);
      if (!this._isDragging) return;
      this._slides.forEach((slide) => {
        slide.classList.add('pointer-events-none');
        slide.classList.remove('pointer-events-auto');
        slide.querySelectorAll('& *').forEach((e) => {
          e.classList.add('select-none');
          e.classList.add('pointer-events-none');
          e.classList.remove('pointer-events-auto');
        });
      });
      requestAnimationFrame(() => this._touchMove());

    }

    _handleMouseUp() {
      this._isDragging = false;
      this._slides.forEach((slide) => {
        slide.classList.add('pointer-events-auto');
        slide.classList.remove('pointer-events-none');
        slide.querySelectorAll('& *').forEach((e) => {
          e.classList.remove('select-none');
          e.classList.remove('pointer-events-none');
          e.classList.add('pointer-events-auto');
        });
      });
      this._currentPosition = this._lastMovementPosition;
      this._container.style.transitionDuration = '';

      const gestureDirection = this._gestureDetection();

      if (gestureDirection) {
        let { newPosition, newIndex } = this._calculateNewPosition(gestureDirection);
        const moveDistance = this._movement.at(-1) - this._movement.at(0);
        this._slide(newPosition - moveDistance, newIndex);
      } else {
        this._snap();
      }

      this._movement = [];
    }

    // -- Touch events --
    _handleTouchStart(event) {
      this._lastEvent = event;
      this._movementStartPosition = event.targetTouches[0].clientX;
      this._lastMovementPosition = this._currentPosition;
      this._movement.push(event.targetTouches[0].clientX);
    }


    _handleTouchMove(event) {
      this._lastEvent = event;
      this._touchAngleCoordinates.push({ x: event.targetTouches[0].clientX, y: event.targetTouches[0].clientY });
      this._touchAngleThresholdSurpassed = this._touchAngleThresholdSurpassed || (this._touchAngle() && (this._touchAngle() > 90 - this._touchAngleMargin && this._touchAngle() < 90 + this._touchAngleMargin));
      if (this._touchAngleThresholdSurpassed) {
        this._touchAngleThresholdSurpassed = true;
        this._disableTransitions();
        this._container.style.touchAction = 'none';
        event.preventDefault();

        if (this._isDragging) return;
        requestAnimationFrame(() => this._touchMove());

        this._isDragging = true;
      }
    }

    _handleTouchEnd(event) {
      if (!this._touchAngleThresholdSurpassed) {
        this._touchAngleCoordinates = [];
        this._movement = [];
        return;
      }
      event.preventDefault();
      this._isDragging = false;
      this._enableTransitions();

      const gestureDirection = this._gestureDetection();

      if (gestureDirection) {
        let { newPosition, newIndex } = this._calculateNewPosition(gestureDirection);
        this._slide(newPosition, newIndex);
      } else {
        // this._lastMovementPosition = (this._movement[this._movement.length - 1] - this._movementStartPosition) + this._currentPosition;
        this._snap();
      }

      this._container.style.touchAction = 'auto';
      this._touchAngleThresholdSurpassed = false;
      this._touchAngleCoordinates = [];
      this._movement = [];
    }

    _touchAngle() {
      let angle = null;
      if (this._touchAngleCoordinates.length >= 2) {
        const c1 = this._touchAngleCoordinates[0];
        const c2 = this._touchAngleCoordinates[1];
        const c3 = { x: this._touchAngleCoordinates[0].x, y: this._touchAngleCoordinates[1].y };

        angle = this._angle(c1, c2, c3);
      }

      return angle;
    }


    _touchMove() {
      if (!this._isDragging) return;


      this._movement.push(this._lastEvent.targetTouches[0].clientX);
      const target = (this._movement.at(-1) - this._movementStartPosition) + this._currentPosition;
      const dist = target - this._lastMovementPosition;
      this._lastMovementPosition = this._lastMovementPosition + dist;
      this._container.style.transform = `translateX(${this._lastMovementPosition}px)`;

      const elementWidth = this._sizes.elements[0].width;
      this._setTouchMovePercentage = this._scaleValue(
        this._lastMovementPosition,
        [this._currentPosition - elementWidth, this._currentPosition + elementWidth],
        [-100, 100]
      ) * -1;

      requestAnimationFrame(() => this._touchMove());
    }

    _gestureDetection() {
      const startingPosition = this._movementStartPosition;
      const lastMovementPosition = this._movement.at(-1);
      const diff = startingPosition - lastMovementPosition;
      return Math.abs(diff) > 50 ? (diff < 0 ? 'previous' : 'next') : 'null';

    }

    _snap() {
      this._updateSizes();
      let snapIndex = 0;
      let closest = Number.MAX_SAFE_INTEGER;
      const sliderParent = this._container.parentElement.getBoundingClientRect();
      for (let i = 0; i < this._slides.length; i++) {
        if (i + this._groupSize - 1 > this._slides.length - 1) break;
        let distanceToTarget;
        if (this._settings.center) {
          const centerOfGroup = (this._sizes.elements[i].left + this._sizes.elements[i + this._groupSize - 1].right) / 2;
          distanceToTarget = ((sliderParent.width / 2) + sliderParent.left) - centerOfGroup;
        } else {
          distanceToTarget = sliderParent.left - this._sizes.elements[i].left;
        }
        if (Math.abs(distanceToTarget) < Math.abs(closest)) {
          closest = distanceToTarget;
          snapIndex = i;
        }
      }

      const newPosition = this._lastMovementPosition + closest;
      this._slide(newPosition, snapIndex);
    }

    _availableSlides(direction) {
      let amountToSlide = 0;
      if (direction === 'next') {
        const endIndex = this._index + this._groupSize - 1;
        const availableSlides = this._slides.length - endIndex - 1;
        amountToSlide = Math.min(this._groupSize, availableSlides);
      } else if (direction === 'previous') {
        amountToSlide = Math.min(this._groupSize, this._index);
      }

      return Math.min(amountToSlide, this._settings.slideBy ?? this._groupSize);
    }

    _handleControlClick(direction) {
      if ((direction === 'next' && this._index + this._groupSize > this._slides.length - 1)
        || (direction === 'previous' && this._index === 0)) {
        return;
      }


      const { newPosition, newIndex } = this._calculateNewPosition(direction);

      this._slide(newPosition, newIndex);
    }

    _calculateNewPosition(direction) {
      // Calculate how many slides lies outside the container
      const availableSlides = this._availableSlides(direction);
      const newPosition = ((this._sizes.slideWidthWithSpace * this._availableSlides(direction)) * (direction === 'next' ? -1 : 1)) + this._currentPosition;
      const newIndex = this._index + (direction === 'next' ? availableSlides : -availableSlides);
      return { newPosition, newIndex };
    }

    _calculateSizes() {
      const emptySpace = this._container.scrollWidth - (this._sizes.elements[0].width * this._slides.length);
      const spaceBetweenSlides = emptySpace / Math.max(this._slides.length - 1, 1); // -1 because the last element shouldn't have any space
      const slideWidthWithSpace = (this._sizes.elements[0].width + spaceBetweenSlides);
      this._sizes.spaceBetweenSlides = spaceBetweenSlides;
      this._sizes.spaceBetweenSlides = spaceBetweenSlides;
      this._sizes.slideWidthWithSpace = slideWidthWithSpace;
    }

    _slide(newPosition, newIndex) {
      this._slides.forEach((slide) => {
        slide.removeAttribute('style');
      });
      this._container.style.transform = `translateX(${newPosition}px)`;
      this._currentPosition = newPosition;
      this._index = newIndex;
      this._setActiveClasses();
      this._setControlClasses();
      this._callOnSlide();
    }

    set onSlideCallback(callback) {
      this._onSlideCallback = callback;
    }

    _callOnSlide() {
      const indices = [...Array(this._groupSize)].map((ignore, i) => this._index + i);
      const elements = [...Array(this._groupSize)].map((ignore, i) => this._index + i);

      this._onSlideCallback(indices, elements);
    }

    _setActiveClasses() {

      this._slides.forEach((element, i) => {
        const isActive = i >= this._index && i <= this._index + this._groupSize - 1;
        element.classList[isActive ? 'add' : 'remove'](...this._elementClasses.active);
        element.classList[isActive ? 'remove' : 'add'](...this._elementClasses.passive);
      });
    }

    _disableTransitions() {
      this._container.style.transitionDuration = 'unset';
      this._slides.forEach((slide) => {
        slide.style.transitionDuration = 'unset';
      });
    }

    _enableTransitions() {
      this._container.style.transitionDuration = '';
      this._slides.forEach((slide) => {
        slide.style.transitionDuration = '';
      });
    }

    _setControlClasses() {
      if (!this._controls.enabled) return;

      const hasNextSlides = !!this._availableSlides('next');
      const hasPreviousSlides = !!this._availableSlides('previous');
      this._nextButton.classList[!hasNextSlides ? 'add' : 'remove'](...this._controls.disabledClasses);
      this._previousButton.classList[!hasPreviousSlides ? 'add' : 'remove'](...this._controls.disabledClasses);
    }

    // Callbacks

    set onPercentageChange(callback) {
      this._percentageChangeCallback = callback;
    }

    set _setTouchMovePercentage(value) {
      const towards = this._index + (value > 0 ? this._groupSize : -1);
      const awayFrom = this._index + (value > 0 ? 0 : this._groupSize - 1);
      if (value !== this._touchMovePercentage) this._percentageChangeCallback(value, towards, awayFrom);
      this._touchMovePercentage = value;
    }

    // Helpers
    _getDistance(c1, c2) {
      return Math.abs(Math.sqrt(Math.pow((c1.x - c2.x), 2) + Math.pow((c1.y - c2.y), 2)));
    }

    _angle(c1, c2, c3) {
      const a = this._getDistance(c2, c3);
      const b = this._getDistance(c1, c3);
      const c = this._getDistance(c1, c2);

      const rightSide = 2 * b * c !== 0 ? 2 * b * c : 1;

      const A = ((b ** 2) + (c ** 2) - (a ** 2)) / rightSide;

      const angle = Math.acos(A);
      return (angle * (180 / Math.PI));
    }

    _scaleValue(value, from, to) {
      const scale = (to[1] - to[0]) / (from[1] - from[0]);
      const capped = Math.min(from[1], Math.max(from[0], value)) - from[0];
      return Math.floor((capped * scale) + to[0]);
    }
  };
});


