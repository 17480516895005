defineDs('/Components/DanskeSpil/Domain/Feature.Navigation/Scripts/NavigationScroll', [], function () {
  const navigation = document.querySelector('.js-navigation');

  if (!navigation) {
    return;
  }

  let isNavigationVisible = true;
  let lastScrollY = window.scrollY;

  const showNavigation = (toggle) => {
    navigation.classList.toggle('show', toggle);
    navigation.classList.toggle('hide', !toggle);

    if (isNavigationVisible !== toggle) {
      isNavigationVisible = toggle;

      if (!isNavigationVisible) {
        // close any open main menu submenus
        document.dispatchEvent(new CustomEvent('navigation:mainMenu:closeAllSubMenus'));
      }
    }
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const nearTop = currentScrollY < 150;
    const nearBottom = window.innerHeight + currentScrollY >= document.body.offsetHeight - 100;

    if (!nearTop && !nearBottom) {
      if (currentScrollY > lastScrollY) {
        showNavigation(false);
      } else {
        showNavigation(true);
      }
    }

    lastScrollY = currentScrollY;
  };

  const initialize = () => {
    document.addEventListener('navigation:scroll', handleScroll);
    document.addEventListener('navigation:scroll:show', () => showNavigation(true));
  };

  initialize();

});


