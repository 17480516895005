defineDs('/Components/DanskeSpil/Domain/Feature.Components/Scripts/TabsController', [], function () {

  const tabsWrapper = document.querySelectorAll('.js-tabs-wrapper');
  tabsWrapper.forEach((element) => {
    const overlay = element.querySelector('.js-overlay');
    const overlayWrapper = element.querySelector('.js-overlay-wrapper');
    const overlayTextWrapper = element.querySelector('.js-overlay-text-wrapper');
    const labelsWrapper = element.querySelector('.js-labels-wrapper');
    const labels = element.querySelectorAll('.js-tab-label') || [];
    const arrow = element.querySelector('.js-overlay-wrapper-arrow');
    const tabContents = element.querySelectorAll('.js-tab-content');
    const articleLinks = element.querySelectorAll('[data-article-id]');

    const getActive = () => [...labels].find((e) => e.dataset.active === 'true');
    const getActiveRect = () => getActive()?.getBoundingClientRect();
    const getLabelsWrapperRect = () => labelsWrapper?.getBoundingClientRect();

    const resizeOverlay = () => {
      if (!overlay || !getActiveRect()) return;
      overlay.style.width = `${getActiveRect().width + 4}px`;
    };

    const resizeOverlayTextWrapper = () => {
      if (!labelsWrapper || !overlayTextWrapper) return;

      const wrapperWidth = labelsWrapper.getBoundingClientRect().width;
      overlayTextWrapper.style.width = `${wrapperWidth}px`;
    };

    const alterPositions = () => {
      if (!overlay || !overlayTextWrapper || !arrow || !labelsWrapper || !getActiveRect()) return;

      resizeOverlay();
      const activeLeft = getActiveRect().left - getLabelsWrapperRect().left;
      overlay.style.left = `${activeLeft - 2}px`;
      overlayTextWrapper.style.left = `${-activeLeft}px`;
      arrow.style.left = `${(getActiveRect().width / 2) + activeLeft}px`;
    };

    labels.forEach((label) => {
      label.addEventListener('click', () => {
        labels.forEach((currentLabel) => {
          currentLabel.dataset.active = (label === currentLabel).toString();
        });

        tabContents.forEach((tc) => {
          tc.classList.toggle('!hidden', tc.dataset.contentId !== label.dataset.tabId);
        });
        alterPositions();
      });
    });

    articleLinks.forEach((link) => {
      link.addEventListener('click', () => {
        const id = link.dataset.articleId;
        const article = element.querySelector('#' + id);
        article.scrollIntoView({ behavior: 'smooth', block: 'start' });
      });
    });


    window.addEventListener('resize', () => {
      resizeOverlayTextWrapper();
      resizeOverlay();
      alterPositions();
    });

    resizeOverlayTextWrapper();
    resizeOverlay();
    alterPositions();

    overlayWrapper.classList.remove('opacity-0');
  });
});
