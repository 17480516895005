defineDs('/Components/DanskeSpil/Domain/Feature.Components/Scripts/Wheels',
  [
    '/Components/Common/Framework/Foundation.SharedScripts/Scripts/Libraries/WheelSlider',
    '/Components/DanskeSpil/Domain/Feature.Components/Scripts/Utils/SliderUtils',
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
    'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoInfo',
  ], function (WheelSlider, SliderUtils, ApiRequest, EurojackpotInfo, LottoInfo, VikingLottoInfo) {
  // ], function (WheelSlider, SliderUtils, ApiRequest, TimeUtils, EurojackpotInfo, LottoInfo, VikingLottoInfo) {

    const wheel = document.querySelector('.js-wheel-container');
    const dotsWrapper = document.querySelector('.js-dots-wrapper');
    const wheelStopper = document.querySelector('.js-wheel-stopper');

    if (!wheel) return;

    const wheelSlider = new WheelSlider({
      containerSelector: wheel,
      controls: {
        enabled: true,
        next: '.js-wheel-next',
        previous: '.js-wheel-prev'
      }
    });

    if (dotsWrapper && wheelStopper) {
      const dots = dotsWrapper.querySelectorAll('div');
      const wheelStopperRect = wheelStopper.getBoundingClientRect();
      const wheelStopperCenter = wheelStopperRect.left + (wheelStopperRect.width / 2);
      let intersectionX = 0;

      let prevClosest;
      let closestAtIntersection;
      let lastClosestRect;
      let intersectionCount = 0;
      dots.forEach((dot, i) => {
        const dotAngle = ((i * 4) - 90) - 10;
        const coords = SliderUtils.getCoordsFromAngle(dotsWrapper, dotAngle);
        dot.dataset.angle = dotAngle.toString();
        dot.style.top = coords.y + 'px';
        dot.style.left = coords.x + 'px';
      });

      wheelSlider.onTotalAngleShiftChange = function (draggedXPosition) {
        dots.forEach((dot, i) => {
          const dotAngle = ((i * 4) - 90) + (draggedXPosition * 1.05) - 10;
          const coords = SliderUtils.getCoordsFromAngle(dotsWrapper, dotAngle);
          dot.dataset.angle = dotAngle.toString();
          dot.style.top = coords.y + 'px';
          dot.style.left = coords.x + 'px';
        });

        const closestDot = getClosestDot(dots);
        const closestDotRect = closestDot.getBoundingClientRect();

        const intersecting = isIntersecting(closestDotRect, wheelStopperRect);
        const skippedDirection = hasSkippedIntersection(lastClosestRect, closestDotRect, wheelStopperCenter, wheelStopperRect, closestDot, prevClosest);

        if (intersecting && closestAtIntersection !== closestDot) {
          intersectionX = draggedXPosition;
          closestAtIntersection = closestDot;
          intersectionCount = 1;
          wheelStopper.style.transitionDuration = '0s';
        } else if (skippedDirection) {
          intersectionCount = 0;
          animateWheelStopper(skippedDirection, 0);
        } else if (!intersecting && intersectionCount && intersectionCount <= 3) {
          intersectionCount = 0;
          animateWheelStopper(intersectionX > draggedXPosition ? 'next' : 'previous', 0);
        } else if (!intersecting && intersectionCount > 3) {
          wheelStopper.style.transitionDuration = '';
          wheelStopper.style.setProperty('--tw-rotate', '0');
          closestAtIntersection = null;
          intersectionCount = 0;
        } else if (intersecting) {
          intersectionCount++;
          const newRotation = Math.min(Math.max((intersectionX - draggedXPosition) * 20, -40), 40);
          wheelStopper.style.setProperty('--tw-rotate', newRotation + 'deg');
        }

        prevClosest = closestDot;
        lastClosestRect = closestDotRect;
      };

      wheelSlider.onDragEnd = function () {
        const closestDot = getClosestDot(dots);

        const closestDotRect = closestDot.getBoundingClientRect();
        const dotsWrapperRect = dotsWrapper.getBoundingClientRect();
        wheelStopper.style.setProperty('--tw-rotate', '0');

        const c1 = {
          x: dotsWrapperRect.left + (dotsWrapperRect.width / 2),
          y: (dotsWrapperRect.height / 2) + dotsWrapperRect.top
        };
        const c2 = {
          x: closestDotRect.left + (closestDotRect.width / 2),
          y: closestDotRect.top + (closestDotRect.height / 2)
        };
        const c3 = { x: dotsWrapperRect.left + (dotsWrapperRect.width / 2), y: dotsWrapperRect.top };

        const angleToMove = (SliderUtils.angle(c1, c2, c3) * 0.8) - 10;

        wheelSlider.updateTotalAngleShift((c1.x > c2.x ? angleToMove : -angleToMove));

        dots.forEach((dot) => {
          const dotAngle = Number(dot.dataset.angle);
          const newDotAngle = dotAngle + (c1.x > c2.x ? angleToMove : -angleToMove);
          const coords = SliderUtils.getCoordsFromAngle(dotsWrapper, newDotAngle);
          dot.style.top = coords.y + 'px';
          dot.style.left = coords.x + 'px';
          dot.dataset.angle = newDotAngle.toString();
        });
      };

      wheelSlider.onNextClick = () => handleControlClick(dots, 'next');
      wheelSlider.onPreviousClick = () => handleControlClick(dots, 'previous');

    }

    const handleControlClick = (dots, direction) => {
      const angleToMove = 20 * (direction === 'next' ? -1 : 1);
      setTimeout(() => {
        animateWheelStopper(direction, 50);
      }, 100);
      wheelSlider.updateTotalAngleShift(angleToMove);

      dots.forEach((dot) => {
        dot.style.transitionDuration = '0s';

        let start;
        let previousTimestamp;
        const moveDotOnButtonClick = (timestamp) => {
          if (!start) start = timestamp;
          const elapsed = Math.min(Math.max((previousTimestamp || timestamp) - start, 1), 300);
          const eased = SliderUtils.easeOut((elapsed / 300));
          const x = 20 * eased * (direction === 'next' ? -1 : 1);
          const dotAngle = Number(dot.dataset.angle);
          const newDotAngle = dotAngle + x;
          const coords = SliderUtils.getCoordsFromAngle(dotsWrapper, newDotAngle);
          dot.style.top = coords.y + 'px';
          dot.style.left = coords.x +  'px';
          previousTimestamp = timestamp;

          if (Math.abs(elapsed) >= 300) {
            dot.style.transitionDuration = '';
            dot.dataset.angle = newDotAngle.toString();
            return;
          }
          requestAnimationFrame(moveDotOnButtonClick);
        };
        requestAnimationFrame(moveDotOnButtonClick);
      });
    };

    const animateWheelStopper = (direction, duration) => {
      const wheelStopperTransitionEnd = () => {
        wheelStopper.style.transitionDuration = '';
        wheelStopper.style.setProperty('--tw-rotate', '0');
        wheelStopper.removeEventListener('transitionend', wheelStopperTransitionEnd);
      };

      wheelStopper.addEventListener('transitionend', wheelStopperTransitionEnd);
      wheelStopper.style.transitionDuration = Math.max(duration, 1) + 'ms';
      wheelStopper.style.setProperty('--tw-rotate', (40 * (direction === 'next' ? 1 : -1)) + 'deg');
    };

    const getClosestDot = (dots) => {
      let shortestDistance = Number.MAX_SAFE_INTEGER;
      let closestDot;

      for (const dot of dots) {
        if (!dot.classList.contains('js-wheel-special-dot')) continue;
        const dotRect = dot.getBoundingClientRect();

        const distance = Math.abs(SliderUtils.getDistance(
          { x: dotRect.left + (dotRect.width / 2), y: dotRect.top + (dotRect.height / 2) },
          { x: wheelSlider._centerX + wheelSlider._containerRect.left, y: wheelSlider._centerY + wheelSlider._containerRect.top }
        ));

        if (distance < shortestDistance) {
          shortestDistance = distance;
          closestDot = dot;
        }
      }
      return closestDot;
    };

    const isIntersecting = (closestDotRect, wheelStopperRect) => !(closestDotRect.right < wheelStopperRect.left + 4 || closestDotRect.left > wheelStopperRect.right - 4);
    const hasSkippedIntersection = (lastClosestRect, closestDotRect, wheelStopperCenter, wheelStopperRect, closestDot, prevClosest) => {
      if (!isIntersecting(closestDotRect, wheelStopperRect) && lastClosestRect && closestDot === prevClosest) {
        const lastDotCenter = lastClosestRect.left + (lastClosestRect.width / 2);
        const closestDotCenter = closestDotRect.left + (closestDotRect.width / 2);
        if (wheelStopperCenter < lastDotCenter && wheelStopperCenter > closestDotCenter) {
          return 'next';
        }

        if (wheelStopperCenter > lastDotCenter && wheelStopperCenter < closestDotCenter) {
          return 'previous';
        }

        return false;
      }
    };

    // ---------------------------- COUNTDOWN AND LOTTIE --------------------------------
    const wheelHeros = document.querySelectorAll('.js-wheel-hero[data-has-countdown="true"]');
    wheelHeros.forEach((wheelHero) => {
      let prevDevice;
      const getTimeData = (region) => {
        if (region === 'eurojackpot') {
          const drawingTime = EurojackpotInfo.data()?.openDraw?.drawingTime;
          const weekday = new Intl.DateTimeFormat('da-DK', { weekday: 'long' }).format(new Date(drawingTime));
          // return EurojackpotInfo.data()?.openDraw?.closingTime;
          return {
            clock: EurojackpotInfo.data()?.openDraw?.closingTime,
            drawingDay: weekday
          };
        } else if (region === 'lotto') {
          const drawingTime = LottoInfo.data()?.lottoSaturday?.openDraw?.drawingTime;
          const weekday = new Intl.DateTimeFormat('da-DK', { weekday: 'long' }).format(new Date(drawingTime));
          // return LottoInfo.data()?.lottoSaturday?.openDraw?.closingTime;
          return {
            clock: LottoInfo.data()?.lottoSaturday?.openDraw?.closingTime,
            drawingDay: weekday
          };
        } else if (region === 'vikinglotto') {
          const drawingTime = VikingLottoInfo.data()?.openDraw?.drawingTime;
          const weekday = new Intl.DateTimeFormat('da-DK', { weekday: 'long' }).format(new Date(drawingTime));
          // return VikingLottoInfo.data()?.openDraw?.closingTime;
          return {
            clock: VikingLottoInfo.data()?.openDraw?.closingTime,
            drawingDay: weekday
          };
        }
      };

      const getDevice = () => {
        let device = 'mobile';
        if (window.matchMedia('(min-width: 1440px)').matches) {
          device = 'desktop';
        } else if (window.matchMedia('(min-width: 768px)').matches) {
          device = 'tablet';
        }
        return device;
      };

      const addLottie = async ({ region, mobileLottieSrc, tabletLottieSrc, desktopLottieSrc, lottiePoolValueReplacementString, poolSizes }) => {
        const device = getDevice();
        if (device === prevDevice) return;
        prevDevice = device;

        let src;
        const player = document.querySelector('.multi-client-top-spot__lottie lottie-player');

        if (device === 'desktop') src = desktopLottieSrc;
        if (device === 'tablet') src = tabletLottieSrc;
        if (device === 'mobile') src = mobileLottieSrc;

        if (src) {
          let strLottie = JSON.stringify(await ApiRequest({ url: src }));

          if (lottiePoolValueReplacementString) {
            const { lotto: lottoPoolSize, vikinglotto: vikinglottoPoolSize, eurojackpot: eurojackpotPoolSize } = poolSizes;
            let poolValue = '';
            if (region === 'eurojackpot') poolValue = eurojackpotPoolSize;
            if (region === 'lotto') poolValue = lottoPoolSize;
            if (region === 'vikinglotto') poolValue = vikinglottoPoolSize;
            strLottie = strLottie.replace(lottiePoolValueReplacementString, poolValue);
          }

          player.load(strLottie);
        } else {
          player.load({});
        }
      };

      const setupLottie = async () => {
        const settings = document.querySelector('.multi-client-top-spot__lottie')?.dataset.settings;
        if (settings == 'KILL IT!') {
          return false;
        }
        if (settings) {
          const { region, mobileLottieSrc, tabletLottieSrc, desktopLottieSrc, lottiePoolValueReplacementString, poolSizes } = JSON.parse(settings);
          await addLottie({ region, mobileLottieSrc, tabletLottieSrc, desktopLottieSrc, lottiePoolValueReplacementString, poolSizes });
          window.addEventListener('resize', () => addLottie({ region, mobileLottieSrc, tabletLottieSrc, desktopLottieSrc, lottiePoolValueReplacementString, poolSizes }));
        }
      };

      const setupTopSpot = (topSpot) => {
        const cardId = topSpot.dataset.cardId;
        const region = topSpot.dataset.region;

        const timeData = getTimeData(region);
        const clock = new Clock(timeData.clock);

        const drawingDayElement = document.querySelectorAll('div[data-card-id="' + cardId + '"] .js-lead-text');
        drawingDayElement.forEach((element) => {
          // check if element contain text "{day}"
          if (element.textContent.includes('{day}')) {
            element.textContent = element.textContent.replace('{day}', timeData.drawingDay);
          }
        });
        topSpot.querySelector('.js-countdown')?.appendChild(clock.el);

        setupLottie();
      };

      const initialize = () => {
        if (document.querySelector('.mode-edit')) return;

        Promise.all([
          EurojackpotInfo.ready,
          LottoInfo.ready,
          VikingLottoInfo.ready,
        ]).then(() => {
          setupTopSpot(wheelHero);
        });
      };

      initialize();

      //------------------------------------------------------------------------------------------
      //------------------------------------------------------------------------------------------
      //------------------------------------------------------------------------------------------

      function CountdownTracker(label, value) {
        const el = document.createElement('span');

        el.className = 'flip-clock__piece';
        el.innerHTML = '<b class="flip-clock__card card">' +
          '<b class="card__top"></b>' +
          '<b class="card__bottom"></b>' +
          '<b class="card__back">' +
          '<b class="card__bottom"></b>' +
          '</b>' +
          '</b>' +
          '<span class="flip-clock__slot">' + label + '</span>';

        this.el = el;

        const top = el.querySelector('.card__top');
        const bottom = el.querySelector('.card__bottom');
        const back = el.querySelector('.card__back');
        const backBottom = el.querySelector('.card__back .card__bottom');

        this.update = function (val) {
          val = ('0' + val).slice(-2);
          if (val !== this.currentValue) {

            if (this.currentValue >= 0) {
              back.setAttribute('data-value', this.currentValue);
              bottom.setAttribute('data-value', this.currentValue);
            }
            this.currentValue = val;
            top.innerText = this.currentValue;
            backBottom.setAttribute('data-value', this.currentValue);

            this.el.classList.remove('flip');
            this.el.offsetWidth;
            this.el.classList.add('flip');
          }
        };

        this.update(value);
      }

      // Calculation adapted from https://www.sitepoint.com/build-javascript-countdown-timer-no-dependencies/

      function getTimeRemaining(endtime) {
        const t = Date.parse(endtime) - Date.parse(new Date());
        return {
          Total: t,
          Dage: Math.floor(t / (1000 * 60 * 60 * 24)),
          Timer: Math.floor((t / (1000 * 60 * 60)) % 24),
          Minutter: Math.floor((t / 1000 / 60) % 60),
          Sekunder: Math.floor((t / 1000) % 60)
        };
      }

      function Clock(countdown) {

        countdown = countdown ? new Date(Date.parse(countdown)) : false;
        const updateFn = countdown ? getTimeRemaining : false;

        if (!countdown) return;
        if (!updateFn) return;

        this.el = document.createElement('div');
        this.el.className = 'flip-clock';

        const t = updateFn(countdown);
        let trackers = {};
        let key; let timeinterval;

        for (key in t) {
          if (key === 'Total') { continue; }
          trackers[key] = new CountdownTracker(key, t[key]);
          this.el.appendChild(trackers[key].el);
        }

        let i = 0;
        function updateClock() {
          timeinterval = requestAnimationFrame(updateClock);

          // throttle so it's not constantly updating the time.
          if (i++ % 10) { return; }

          const t = updateFn(countdown);
          if (t.Total < 0) {
            cancelAnimationFrame(timeinterval);
            for (key in trackers) {
              trackers[key].update(0);
            }
            return;
          }

          for (key in trackers) {
            trackers[key].update(t[key]);
          }
        }

        setTimeout(updateClock, 500);
      }
    });
  });
