defineDs('/Components/DanskeSpil/Domain/Feature.Components/Scripts/Utils/Focus', [], () => {
  const focusableElements = 'a[href], button:not([disabled]), textarea, input, select, [tabindex]:not([tabindex="-1"])';

  const setFocus = (containerElement) => {
    // Find all focusable elements in the menu
    const firstFocusableElement = containerElement.querySelectorAll(focusableElements)[0];

    // Update tabindex to make them focusable
    containerElement.querySelectorAll(focusableElements)
      .forEach((f) => f.setAttribute('tabindex', '0'));

    // Set focus to the first focusable element
    setTimeout(() => {
      firstFocusableElement.focus();
    }, 50);
  };

  const removeFocus = (containerElement) => {
    // Reset tabindex to make elements unfocusable again
    containerElement.querySelectorAll(focusableElements).forEach((f) => f.setAttribute('tabindex', '-1'));
  };

  return {
    setFocus,
    removeFocus,
  };

});
