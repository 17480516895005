defineDs('/Components/DanskeSpil/Domain/Feature.Components/Scripts/Modules/ModalPopup', [
  '/Components/DanskeSpil/Domain/Feature.Components/Scripts/Utils/Focus',
], function (Focus) {
  const modal = document.querySelector('.js-modal-popup');

  const initialize = () => {
    let lastFocusedElement;
    // const focusableElements = 'a[href], button:not([disabled]), textarea, input, select, [tabindex]:not([tabindex="-1"])';
    const closeModalButtons = document.querySelectorAll('.js-modal-popup-close');

    const openModal = ({ title, text, cta }) => {
      lastFocusedElement = document.activeElement;
      modal.querySelector('.js-modal-popup-title').innerHTML = title;
      modal.querySelector('.js-modal-popup-text').innerHTML = text;

      const buttonsContainer = modal.querySelector('.js-modal-popup-buttons');
      const ctaElement = buttonsContainer.querySelector('.js-modal-popup-button-cta');
      ctaElement.classList.remove('show');

      if (cta && cta.label && typeof cta.cb === 'function') {
        ctaElement.classList.toggle('ds-modal-popup__button--primary', cta.isPrimary);
        ctaElement.addEventListener('click', cta.cb);
        ctaElement.innerHTML = cta.label;
        ctaElement.classList.add('show');
      }

      modal.classList.add('open');
      document.dispatchEvent(new CustomEvent('overlay:modal:show'));

      Focus.setFocus(modal);
    };

    const closeModal = () => {
      modal.classList.remove('open');
      Focus.removeFocus(modal);
      document.dispatchEvent(new CustomEvent('overlay:modal:hide'));
      lastFocusedElement?.focus();
    };

    // Event listeners
    document.addEventListener('modal:open', ({ detail: { title, text, cta = null } }) => {
      openModal({ title, text, cta });
    });

    document.addEventListener('modal:close', closeModal);

    closeModalButtons.forEach((element) => {
      element.addEventListener('click', closeModal);
    });

  };

  if (modal) {
    initialize();
  }

});


