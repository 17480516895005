defineDs('/Components/DanskeSpil/Domain/Feature.Navigation/Scripts/NavigationSideMenu', [], function () {
  const menus = document.querySelectorAll('.js-navigation-side-menu');

  if (menus.length === 0) {
    return;
  }

  const initialize = () => {
    menus.forEach((menu) => {
      const mainButtons = menu.querySelectorAll('& > li > button');
      const subButtons = menu.querySelectorAll('& ul > li > button');
      const activeLink = menu.querySelector('.active');

      mainButtons?.forEach((button) => {
        button.addEventListener('click', () => {
          button.closest('li').classList.toggle('open');
        });
      });

      subButtons?.forEach((button) => {
        button.addEventListener('click', () => {
          button.closest('li').classList.toggle('open');
        });
      });

      // open active containers so the user knows where he is
      if (activeLink) {
        const parentContainer = activeLink.closest('ul').closest('li');
        parentContainer.classList.add('open');
        if (parentContainer.closest('ul').closest('li') !== null) {
          parentContainer.closest('ul').closest('li').classList.add('open');
        }
      }
    });
  };

  initialize();

});


