defineDs('/Components/Common/Framework/Foundation.SharedScripts/Scripts/Utils/SliderUtils', [], () => {

  const toRadians = (degrees) => (degrees * Math.PI) / 180;

  const angle = (c1, c2, c3) => {
    const a = getDistance(c2, c3);
    const b = getDistance(c1, c3);
    const c = getDistance(c1, c2);

    const rightSide = 2 * b * c !== 0 ? 2 * b * c : 1;

    const A = ((b ** 2) + (c ** 2) - (a ** 2)) / rightSide;

    const angle = Math.acos(A);
    return (angle * (180 / Math.PI));
  };

  const getDistance = (c1, c2) => {
    return Math.sqrt(Math.pow(c1.x - c2.x, 2) + Math.pow(c1.y - c2.y, 2));
  };

  const easeOut = (x) => {
    return Math.sin((x * Math.PI) / 2);
  };

  const getCoordsFromAngle = (wrapper, angle) => {
    return {
      x: ((wrapper.getBoundingClientRect().width / 2) * Math.cos(toRadians(angle))) + (wrapper.getBoundingClientRect().width / 2),
      y: ((wrapper.getBoundingClientRect().height / 2) * Math.sin(toRadians(angle))) + (wrapper.getBoundingClientRect().height / 2)
    };
  };

  const scaleValue = (value, from, to) => {
    const scale = (to[1] - to[0]) / (from[1] - from[0]);
    const capped = Math.min(from[1], Math.max(from[0], value)) - from[0];
    return (capped * scale) + to[0];
  };

  return {
    angle,
    toRadians,
    getDistance,
    easeOut,
    getCoordsFromAngle,
    scaleValue
  };

});
